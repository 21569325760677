/**
 * OneTrust CMP
 *
 * Use to overwrite styles in OneTrust components;
 * Must use !important
 */

/* Page styles */
#ot-sdk-cookie-policy #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy #cookie-policy-title {
    @apply text-h4 text-white mb-4;
}

#ot-sdk-cookie-policy #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy #cookie-policy-description {
    @apply text-body1 text-white mb-8;
}

#ot-sdk-cookie-policy #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-cookie-policy-group {
    @apply text-h5 text-white mb-2;
}

#ot-sdk-cookie-policy #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-cookie-policy-group-desc {
    @apply text-body1 text-white mb-8;
}

#ot-sdk-cookie-policy #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy thead {
    @apply bg-transparent;
}

#ot-sdk-cookie-policy #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table th {
    @apply bg-transparent text-body1 text-white;
}

#ot-sdk-cookie-policy #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table th,
#ot-sdk-cookie-policy #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table td {
    border-bottom-width: 2px;
    border-right-width: 2px;

    @apply border-gray-3 p-4;
}

#ot-sdk-cookie-policy #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table th,
#ot-sdk-cookie-policy #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table td,
#ot-sdk-cookie-policy #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table td span {
    @apply text-white text-body1;
}

#ot-sdk-cookie-policy #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table td a {
    @apply bg-transparent text-white;
}

#ot-sdk-cookie-policy #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table {
    @apply border-gray-3;
    border-inline: none;
    border-block: none;
}

#ot-sdk-cookie-policy #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table tr th:last-child,
#ot-sdk-cookie-policy #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table tr td:last-child {
    border-right: none;
}

#ot-sdk-cookie-policy #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-subgroup {
    @apply ml-0;
}

#ot-sdk-cookie-policy #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-subgroup ul li {
    @apply mb-6;
}

#ot-sdk-cookie-policy #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-subgroup ul li::marker {
    @apply text-white;
}

#ot-sdk-cookie-policy #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-subgroup .ot-sdk-cookie-policy-group,
#ot-sdk-cookie-policy #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-subgroup .ot-sdk-cookie-policy-group-desc {
    @apply text-body1;
}

#ot-sdk-cookie-policy #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-subgroup .ot-sdk-cookie-policy-group-desc {
    @apply mb-6;
}

#ot-sdk-cookie-policy #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-subgroup .ot-sdk-cookie-policy-group {
    @apply mb-0;
}

/* Modal styles */

body #onetrust-consent-sdk #onetrust-pc-sdk h2,
body #onetrust-consent-sdk #onetrust-pc-sdk h3,
body #onetrust-consent-sdk #onetrust-pc-sdk h4 {
    @apply font-semibold;
}

body #onetrust-consent-sdk #onetrust-pc-sdk h3 {
    @apply text-body2;
}

body #onetrust-consent-sdk #onetrust-pc-sdk {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);

    display: grid;
    grid-template-areas: "header"
                         "content"
                         "footer";
    grid-template-rows: 64px 1fr 118px;

    @apply bg-black;
}

body #onetrust-pc-sdk #ot-pc-lst,
body #onetrust-pc-sdk #ot-pc-content {
    grid-area: content;
    position: absolute;
    top: 0;
    bottom: 0;
}

body #onetrust-pc-sdk #ot-pc-content {
    margin: 0;
    padding-inline: 30px;
    padding-top: 8px;
    padding-bottom: 24px;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-pc-header {
    grid-area: header;
    height: 64px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-pc-footer {
    grid-area: footer;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-pc-header,
body #onetrust-consent-sdk #onetrust-pc-sdk .ot-pc-footer {
    @apply bg-black-light border-none;
}

body #onetrust-consent-sdk #ot-pc-content #ot-pc-title {
    @apply text-body1 mb-2;
}

body #onetrust-consent-sdk #ot-pc-content #ot-pc-desc {
    @apply text-overline mb-6;
}

body #onetrust-consent-sdk #ot-pc-content .privacy-notice-link {
    @apply inline-block text-overline text-red-medium mt-2;
}

body #onetrust-consent-sdk #ot-pc-content #accept-recommended-btn-handler {
    @apply rounded-[50px] bg-red text-body2 py-2 mb-4;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-cat-item {
    @apply mt-6 flex flex-wrap;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-cat-item .ot-always-active {
    line-height: 1.5rem;

    @apply text-overline text-gray-4;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-cat-header {
    @apply text-body1 flex-grow;
}


body #onetrust-consent-sdk #onetrust-pc-sdk .ot-switch-nob {
    @apply bg-black-light border-none;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-switch-nob::before {
    @apply bg-gray-4 bottom-[2px] left-[2px];
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-tgl input:checked+.ot-switch .ot-switch-nob {
    @apply bg-red;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-tgl input:checked+.ot-switch .ot-switch-nob::before {
    @apply bg-red-light-darker;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-category-desc {
    @apply text-overline flex-grow mt-4 mb-2;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-hlst-cntr {
    @apply flex-grow mt-0;
}

body #onetrust-consent-sdk #onetrust-pc-sdk a,
body #onetrust-consent-sdk #onetrust-pc-sdk .ot-link-btn {
    @apply text-overline text-red-medium underline underline-offset-2 mt-0;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .category-vendors-list-handler+a::after {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.6401 14.3307H1.69341L1.66675 13.6374V1.69073L2.36008 1.66406H6.46675C6.58008 1.66406 6.66675 1.7574 6.66675 1.86406V2.79073C6.66675 2.90406 6.57341 2.99073 6.46675 2.99073H3.20008C3.08675 2.99073 3.00008 3.08406 3.00008 3.19073V12.7841C3.00008 12.8974 3.09341 12.9841 3.20008 12.9841H12.7934C12.9067 12.9841 12.9934 12.8907 12.9934 12.7841V9.52406C12.9934 9.41073 13.0867 9.32406 13.1934 9.32406H14.1201C14.2334 9.32406 14.3201 9.4174 14.3201 9.52406V14.2974L13.6267 14.3241L13.6401 14.3307Z' fill='%23EB5757'/%3E%3Cpath d='M9.33328 1.86406V2.79073C9.33328 2.90406 9.42661 2.99073 9.53328 2.99073H11.5666C11.7466 2.99073 11.8399 3.21073 11.7133 3.3374L7.67328 7.3774C7.59328 7.4574 7.59328 7.58406 7.67328 7.66406L8.32661 8.3174C8.40661 8.3974 8.53328 8.3974 8.61328 8.3174L12.6533 4.2774C12.7799 4.15073 12.9999 4.2374 12.9999 4.42406V6.4574C12.9999 6.57073 13.0933 6.6574 13.1999 6.6574H14.1266C14.2399 6.6574 14.3266 6.56406 14.3266 6.4574V1.86406C14.3266 1.75073 14.2333 1.66406 14.1266 1.66406H9.53328C9.41995 1.66406 9.33328 1.7574 9.33328 1.86406Z' fill='%23EB5757'/%3E%3C/svg%3E");
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-vs-config .ot-subgrp-cntr ul.ot-subgrps li.ot-subgrp h5 {
    @apply text-body2 font-semibold;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-vs-config .ot-subgrp-cntr ul.ot-subgrps li.ot-subgrp p {
    @apply text-overline mb-4;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-subgrp .ot-leg-btn-container {
    @apply flex justify-between items-center;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-subgrp .ot-leg-btn-container .ot-obj-leg-btn-handler {
    @apply rounded-[50px] py-2 px-8 text-black;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-subgrp .ot-leg-btn-container .ot-active-leg-btn svg path {
    fill: #121113;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-subgrp .ot-leg-btn-container .ot-active-leg-btn {
    @apply bg-gray-3 border-none text-body2 max-w-full flex items-center;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-subgrp .ot-leg-btn-container .ot-inactive-leg-btn {
    @apply bg-white text-body2 border-none;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-leg-btn-container .ot-remove-objection-handler {
    @apply text-overline;

    /* It must be important because it have color as inline style */
    color: #EB5757 !important;
}

body #onetrust-pc-sdk .ot-pc-footer .ot-btn-container {
    @apply text-center;
}

body #onetrust-pc-sdk .ot-obj-leg-btn-handler span {
    @apply font-semibold;
}

body #onetrust-pc-sdk .ot-pc-footer .ot-btn-container .onetrust-close-btn-handler {
    @apply text-body2 text-red rounded-2 py-3 px-16 mt-6 font-semibold;
}

body #onetrust-pc-sdk .ot-pc-footer .ot-pc-footer-logo {
    @apply bg-black-light;
}

#onetrust-consent-sdk #onetrust-policy-title {
    @apply text-h5;
}

#onetrust-consent-sdk #onetrust-banner-sdk .ot-dpd-desc {
    @apply mb-0;
}

#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-policy-text,
#onetrust-consent-sdk #onetrust-banner-sdk .ot-dpd-desc, #onetrust-banner-sdk .ot-b-addl-desc {
    @apply text-overline;
}

#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-policy-text a {
    @apply text-red-medium text-overline underline underline-offset-2;
}

#onetrust-consent-sdk #onetrust-banner-sdk .ot-dpd-title {
    @apply text-body2 mb-2;
}

#onetrust-consent-sdk #onetrust-banner-sdk .ot-link-btn {
    @apply text-overline text-red-medium underline underline-offset-2;
}

#onetrust-consent-sdk #onetrust-banner-sdk.ot-iab-2 #onetrust-button-group button {
    @apply rounded-2 text-body2 py-2;
}

#onetrust-consent-sdk #onetrust-banner-sdk.ot-iab-2 #onetrust-button-group #onetrust-accept-btn-handler {
    @apply text-red-light w-full;
}

#onetrust-consent-sdk #onetrust-banner-sdk.ot-iab-2 #onetrust-button-group #onetrust-pc-btn-handler {
    @apply bg-black text-white border-2 border-white;
}

#onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container {
    max-width: 1522px;
    margin: 0 auto;
}

#onetrust-consent-sdk #onetrust-pc-sdk li .ot-acc-txt {
    box-sizing: border-box;

    @apply p-4 bg-black-extra mt-5 rounded-2;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-acc-txt .ot-ven-dets span {
    @apply text-overline text-white font-semibold;
}

#onetrust-consent-sdk #onetrust-pc-sdk li .ot-acc-txt .ot-ven-disc {
    margin-bottom: 1rem !important;

    @apply p-0 flex items-center flex-wrap;
}

#onetrust-consent-sdk #onetrust-pc-sdk li .ot-acc-txt .ot-ven-disc span {
    @apply ml-1;
}

#onetrust-consent-sdk #onetrust-pc-sdk li .ot-acc-txt .ot-ven-disc span + p {
    @apply p-0 mt-3;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-ven-dets .ot-ven-pur {
    border-top: 1px solid !important;

    @apply border-t-gray-1;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-ven-dets .ot-ven-pur h4 {
    @apply mb-2;
}

#onetrust-consent-sdk #onetrust-pc-sdk li .ot-acc-txt .ot-ven-disc h4,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-ven-dets .ot-ven-pur h4 {
    @apply border-none text-overline p-0;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-ven-dets .ot-ven-pur ul li {
    list-style: disc;
    margin-left: 20px !important;
    line-height: 20px;

    @apply p-0;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-ven-dets .ot-ven-pur ul p {
    @apply text-small p-0;
}

@media only screen and (min-width: 426px) and (max-width: 896px) {
    #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group {
        @apply flex justify-between items-center px-4;
    }

    #onetrust-consent-sdk #onetrust-banner-sdk.ot-iab-2 #onetrust-button-group button {
        @apply w-full;
    }

    #onetrust-consent-sdk #onetrust-banner-sdk.ot-iab-2 #onetrust-button-group #onetrust-pc-btn-handler {
        @apply mr-0;
    }
}

@media (min-width: 550px) {
    #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-ten.ot-sdk-columns {
        width: 100%;
    }
}

@media only screen and (min-width: 769px) {
    #onetrust-consent-sdk #onetrust-banner-sdk.ot-iab-2 #onetrust-button-group {
        @apply mr-0;
    }
}

@media (min-width: 896px) {
    #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-ten.ot-sdk-columns {
        width: 100%;
    }

    #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-row {
        @apply flex justify-between;
    }
}

@media only screen and (min-width: 897px) {
    #onetrust-consent-sdk #onetrust-banner-sdk.vertical-align-content #onetrust-button-group-parent {
        position: unset;
        top: auto;
        left: auto;
        transform: unset;
        margin-left: 0;
        margin-right: auto;

        width: 100%;
        max-width: 200px;

        @apply flex flex-col justify-center px-4;
    }

    #onetrust-consent-sdk #onetrust-banner-sdk.ot-iab-2 #onetrust-button-group #onetrust-pc-btn-handler {
        @apply mr-0 w-full;
    }
}

@media only screen and (min-width: 1024px) {
    #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-policy {
        margin: 0;
        padding: 2rem;

        display: grid;
        grid-template-areas: 'title title'
                             'first second';
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 48px;
    }

    #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-policy #onetrust-policy-title {
        grid-area: title;
    }

    #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-policy #onetrust-policy-text {
        grid-area: first;
        width: 100%;
        padding-right: 1.5rem;
        padding-bottom: 0;
    }

    #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-policy .ot-dpd-container {
        grid-area: second;
        width: 100%;
        padding-left: 0;
    }

    #onetrust-consent-sdk #onetrust-banner-sdk.ot-iab-2 #onetrust-button-group {
        width: 100%;
    }
}

body #onetrust-consent-sdk #onetrust-banner-sdk *:focus,
body #onetrust-consent-sdk #onetrust-banner-sdk:focus {
    outline: none;
}

/* Modal with checkboxes */
#onetrust-consent-sdk #onetrust-pc-sdk .ot-chkbox input[type="checkbox"]:focus + label::before,
body #onetrust-consent-sdk #onetrust-pc-sdk *:focus,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-vlst-cntr > a:focus {
    outline: none;
}

#onetrust-consent-sdk #onetrust-pc-sdk #ot-lst-title {
    @apply my-6;
}

#onetrust-consent-sdk #onetrust-pc-sdk #ot-lst-title #ot-back-arw path {
    fill: #ffffff;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-sel-all {
    float: unset;
    border-bottom: 2px solid #fff;
    @apply flex items-center justify-between pb-6 bg-black;
}

body #onetrust-consent-sdk #onetrust-pc-sdk #ot-sel-blk {
    @apply bg-transparent;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-consent-hdr {
    @apply font-semibold;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-consent-hdr,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-li-hdr {
    float: unset;
    text-align: left;

    @apply text-body2;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-sel-all-chkbox {
    width: auto;
    margin-right: 28px;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-sel-all-chkbox .ot-chkbox {
    float: unset;
}

#onetrust-consent-sdk #onetrust-pc-sdk #ot-host-lst .ot-host-item {
    @apply ml-0;
}

#onetrust-consent-sdk #onetrust-pc-sdk #ot-host-lst .ot-host-item .ot-host-hdr .ot-host-name {
    @apply text-body2;
}

#onetrust-consent-sdk #onetrust-pc-sdk #ot-host-lst .ot-host-item .ot-host-hdr .ot-host-expand {
    @apply text-overline mt-2 underline underline-offset-2;
}

#onetrust-consent-sdk #onetrust-pc-sdk ul li {
    @apply py-4 px-0 border-b-2 ml-4;
}

#onetrust-consent-sdk #onetrust-pc-sdk ul li.ot-ven-item {
    @apply border-gray-3 ml-0;
}

body #onetrust-pc-sdk .ot-host-opt li>div div {
    @apply text-small p-0;
}

body #onetrust-consent-sdk #onetrust-pc-sdk #ot-host-lst .ot-host-info {
    @apply bg-black-extra ml-0 mb-0 p-0;
}

body #onetrust-consent-sdk #onetrust-pc-sdk #ot-host-lst .ot-host-info > div:not(:last-child) {
    @apply mb-2;
}

body #onetrust-consent-sdk #onetrust-pc-sdk button {
    max-width: 100%;
    letter-spacing: normal !important;
}

body #onetrust-consent-sdk #onetrust-pc-sdk button:focus {
    @apply border-none;
}

#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst .ot-acc-hdr {
    @apply flex items-start justify-between;
}

#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst .ot-acc-hdr .ot-ven-name {
    @apply text-body2;
}

#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst .ot-acc-hdr .ot-ven-link {
    @apply text-overline pt-0 mt-2;
}

#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst .ot-tgl-cntr {
    float: none;
    position: relative;
    right: unset;

    @apply flex items-center mt-0 h-auto;
}

#onetrust-consent-sdk #onetrust-pc-sdk #ot-lst-cnt {
    @apply mt-8 max-h-[calc(100%-150px)];
}

#onetrust-consent-sdk #onetrust-pc-sdk #ot-lst-cnt .ot-chkbox {
    @apply ml-0;
}

#onetrust-consent-sdk #onetrust-pc-sdk #ot-lst-cnt .ot-chkbox,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-lst-cnt .ot-chkbox label {
    @apply w-6 h-6;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-chkbox label::before,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-chkbox label::after {
    box-sizing: border-box;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-sel-all-chkbox .ot-chkbox.line-through label::after {
    top: 11px;
    left: 8px;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-chkbox label::before {
    @apply h-6 w-6 border-white bg-black rounded-[4px];
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-chkbox label::after {
    @apply left-[7px] top-[9px];
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-chkbox input:checked ~ label::before {
    @apply bg-red-medium border-red-medium border-none;
}

#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst .ot-arw-cntr {
    float: none;
    @apply ml-10;
}

#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst .ot-arw {
    @apply ml-0 w-6 h-6 text-white;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-acc-txt .ot-ven-dets {
    @apply bg-black-extra;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-ven-dets .ot-ven-disc p,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-ven-dets .ot-ven-disc h4 {
    @apply text-small;
}

#onetrust-consent-sdk #onetrust-pc-sdk #filter-btn-handler {
    @apply bg-white border-none h-12 w-12 rounded-full flex items-center justify-center;
}

#onetrust-consent-sdk #onetrust-pc-sdk #filter-btn-handler svg {
    @apply h-6 w-6 m-0;
}

#onetrust-consent-sdk #onetrust-pc-sdk #filter-btn-handler svg path {
    fill: #121113;
}

body #onetrust-consent-sdk #onetrust-pc-sdk #ot-anchor {
    border-width: 7px;

    @apply bg-white shadow-none right-[40px] top-[124px] absolute;
}

#onetrust-consent-sdk #onetrust-pc-sdk #ot-fltr-modal {
    @apply max-h-[300px] h-[90%] absolute top-[120px] -right-3;
}

body #onetrust-consent-sdk #onetrust-pc-sdk #ot-fltr-cnt {
    @apply bg-white shadow-none rounded-2;
}

body #onetrust-consent-sdk #onetrust-pc-sdk #clear-filters-handler {
    @apply text-overline text-red-medium underline underline-offset-2 mt-3;
}

body #onetrust-consent-sdk #onetrust-pc-sdk .ot-chkbox label span {
    padding-top: 2px;

    @apply text-small text-black pl-2;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-fltr-opt {
    @apply mb-2 w-full min-h-[1.5rem] ml-0;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-fltr-opt .ot-chkbox label::before,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-fltr-opt .ot-chkbox label::after {
    grid-area: checkbox;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-fltr-opt .ot-chkbox label::before {
    box-sizing: border-box;

    @apply bg-red-light border-red-medium relative;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-fltr-opt .ot-chkbox label {
    display: grid;
    grid-template-areas: "checkbox label";
    grid-template-columns: 1.5rem 1fr;

    @apply pl-3;
}

#onetrust-consent-sdk #onetrust-pc-sdk #vendor-search-handler {
    float: none;

    @apply ml-0 h-12 bg-gray-1 rounded-[10px] border-none text-body2 text-gray-3 pl-12;
}

#onetrust-consent-sdk #onetrust-pc-sdk #vendor-search-handler::placeholder {
    @apply not-italic;
}

#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-hdr {
    @apply m-0 px-6;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-lst-subhdr {
    @apply flex justify-between;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-fltr-cntr {
    float: unset;
    right: 0;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-search-cntr {
    float: unset;

    @apply flex items-center;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-search-cntr > svg {
    float: unset;

    @apply left-4 top-2 right-auto;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-search-cntr > svg path {
    fill: #ffffff;
}

#ot-sdk-cookie-policy + #ot-sdk-btn.ot-sdk-show-settings {
    @apply bg-red text-white border-none text-body2;
}

#onetrust-pc-sdk .ot-fltr-btns > button {
    @apply rounded-2;
}

#onetrust-pc-sdk b {
    font-weight: 600 !important;
}

#onetrust-pc-sdk .ot-chkbox:not(.line-through) input[type="checkbox"]:checked + label::after {
    content: url("data: image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.9125 6.1432C18.7182 5.94706 18.403 5.94706 18.2086 6.1432L9.53744 14.8927L5.78411 11.1103C5.5896 10.9143 5.27425 10.9143 5.07974 11.1103L4.14197 12.0553C3.94747 12.2513 3.94747 12.5691 4.14197 12.7652L7.89634 16.5487L7.89342 16.5516L9.18256 17.8524C9.37695 18.0485 9.6921 18.0485 9.88648 17.8524L19.8497 7.79913C20.0441 7.60299 20.0441 7.28499 19.8497 7.08885L18.9125 6.1432Z' fill='white'/%3E%3C/svg%3E%0A") !important;
    transform: none !important;
    border: none !important;
    top: 0 !important;
    left: 0 !important;
}

/* Floating cookie button to edit choices */
.ot-floating-button__front {
  /* Example usage: */
  /*background-image: url(https://images.ctfassets.net/6fq844wwrixt/2qwlnsWC8jucc01vUXePm8/9e47ed98b1d9acfebdafc219bfcaae65/ECH_Logo_Navigation_32x32px.svg?w=32&q=75&fm=webp) !important;*/
}

/* Akzeptieren */
#onetrust-accept-btn-handler {
  /* Example usage: */
  /*font-family: "Comic Sans MS", serif !important;*/
  /*border-radius: 10px !important;*/
}

/* Alle ablehnen */
#onetrust-reject-all-handler {

}

/* Zwecke anzeigen */
#onetrust-pc-btn-handler {

}

/* Root container */
#onetrust-banner-sdk {

}

/* Container heading */
#onetrust-policy-title {

}

/**
 * Edit preferences container
 */

/* Container */
#onetrust-pc-sdk {

}

/* Alle zulassen */
#accept-recommended-btn-handler {

}

/*Immer aktiv */
.ot-always-active {

}

/* Alle ablehnen */
.ot-pc-refuse-all-handler {

}

/* Meine Auswahl bestätigen */
.save-preference-btn-handler {

}
