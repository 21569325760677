@import url('one-trust.css');
@import url('smartbanner.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

:focus-visible {
  outline: none;
}

/* Styles for progress bar indicating page change */
#nprogress {
  @apply pointer-events-none;
}

#nprogress .bar {
  @apply bg-red w-full fixed top-0 left-0 h-1;
  z-index: 1031;
}

#nprogress .peg {
  @apply block absolute right-0 h-full opacity-100;
  width: 100px;
  box-shadow: 0 0 10px theme('colors.red.DEFAULT'),
    0 0 5px theme('colors.red.DEFAULT');
  transform: rotate(3deg) translate(0, -4px);
}

.esn #nprogress .bar {
  @apply bg-esn-primary;
}

.esn #nprogress .peg {
  box-shadow: 0 0 10px theme('colors.esn.primary.DEFAULT'),
    0 0 5px theme('colors.esn.primary.DEFAULT');
}

/* For smooth anchor scrolling */
html {
  scroll-behavior: smooth;
}

/* Styles for ads */
.adm-loaded + .ad-ech-placeholder,
#ad-sba-slot-sba1 + .ad-ech-placeholder,
#ad-sba-slot-sba2 + .ad-ech-placeholder {
  display: none;
}

/* Styling for MHPA ad slots that contain an ad */
.ad-slot:has(.ad-mr-slot-mhpa2:not(:empty)),
.ad-slot:has(.ad-mr-slot-mhpa3:not(:empty)) {
  height: 600px;
}

.ad-mr-slot-mhpa2,
.ad-mr-slot-mhpa3 {
  position: sticky;
  top: calc(64px + 1rem);
}

/* Energy One */
.one-app-root .faq {
  margin-bottom: 0 !important;
}

/* Recaptcha badge */
.grecaptcha-badge {
  z-index: 100;
}

.jw-controls .jw-display {
  z-index: 10;
}

/* Hack for safari */
.safari-100vh-hack {
  height: calc(100vh - calc(100vh - 100%));
}

.PhoneInputCountrySelect {
  @apply bg-black text-gray-2;
}

.text-border {
  text-shadow: 2px 2px 0 #000000, 2px 0 #000000, -2px 0 #000000, 0 2px #000000,
    0 -2px #000000, 1px 1px #000000, -1px -1px #000000, 1px -1px #000000,
    -1px 1px #000000;
}

.ticket-placeholder-gradient {
  background: linear-gradient(
    51.57deg,
    #aa57eb -12.76%,
    #efaae8 56.26%,
    #f9d2e5 68.34%,
    #efaae8 81.28%,
    #aa57eb 152.9%
  );
}

.qr-code-card-gradient {
  background: linear-gradient(180deg, #1211131a 0%, #121113bf 100%);
}

@keyframes infinite-scroll {
  0% {
    transform: translateX(0);
  }
  100% {
  transform:translateX(calc(-100% - 1rem));
  }
}

.animate-infinite-scroll {
  animation: infinite-scroll linear infinite;
  white-space: nowrap;
  display: flex;
  justify-content: flex-end;
}
